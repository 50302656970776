import Link from "next/link";
import React from "react";
import styles from "./TermItem.module.css";
import { useTranslation } from "../context/TranslationContext";
const TermItem = ({ data }) => {
  const { nights } = useTranslation();

  return (
    <div className="flex-none px-2 lg:py-2 w-44 sm:w-5/12 lg:w-4/12 xl:w-1/6 first:pl-0 last:pr-0">
      <div className="bg-kisasureli-bg rounded-md py-5 px-4 flex flex-col transform transition-shadow hover:shadow-xl">
        <span className="bg-white rounded-sm text-black text-15 text-center p-3 font-medium">
          {data.ay}
        </span>
        {data.deger.map((day, dayindex) => {
          return (
            <Link href={day.url} key={dayindex}>
              <a
                title={day.title}
                className={
                  "mt-3 flex justify-between text-13 items-center ml-1 group " +
                  styles.ShortTermDay
                }
              >
                <span className="text-brand-black-2 font-bold">
                  {day.gece}{" "}
                  <span className="font-normal lg:ml-3">{nights}</span>
                </span>
                <span className="text-white bg-kisasureli-count-bg bg-transparent transform transition-all duration-200 group-hover:bg-none group-hover:text-brand-orange-2 rounded-2xl w-14 text-13 text-center py-1 group-hover:bg-white">
                  {day.adet}
                </span>
              </a>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default TermItem;
