import React, { useEffect, useState } from "react";
import styles from "./ShortTerms.module.css";
import { BsThreeDots } from "@react-icons/all-files/bs/BsThreeDots";

import TermItem from "../TermItem";
import { HomeShortTerms } from "../api";
import Skeleton from "react-loading-skeleton";
import Link from "next/link";
import { useTranslation } from "../../context/TranslationContext";

const ShortTerms = ({ hideTitle }) => {
  const { shortTermsTitle, all } = useTranslation();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(false);

  useEffect(() => {
    HomeShortTerms().then((result) => {
      setData(result.data.data);
      setPage(result.data.page);
    });
  }, []);

  return (
    <div
      className={
        (hideTitle ? styles.HideTitle : "mt-12 lg:mt-36 pb-24") +
        " relative " +
        styles.ShortTermsBg
      }
    >
      <div className="container flex flex-col lg:items-center gap-y-6 lg:gap-y-0">
        {!hideTitle && (
          <div className="flex justify-between items-center">
            <h3 className="font-bold text-xl lg:text-3xl text-brand-black lg:text-orange lg:leading-69 lg:mb-10">
              {shortTermsTitle}
            </h3>
            {page && (
              <Link href={page.url}>
                <a
                  title={page.title}
                  className="text-brand-black font-bold text-13 lg:hidden self-end"
                >
                  {all}{" "}
                  <BsThreeDots
                    size="36"
                    className="inline-block text-brand-blue"
                  ></BsThreeDots>
                </a>
              </Link>
            )}
          </div>
        )}

        <div className="flex w-full overflow-x-auto lg:flex-wrap pb-5 lg:pb-0">
          {data.length == 0 ? (
            <>
              <div className="flex-none px-2 lg:py-2 w-44 sm:w-5/12 lg:w-4/12 xl:w-1/6 first:pl-0 last:pr-0 h-[300px]">
                <Skeleton className="w-full h-full"></Skeleton>
              </div>
              <div className="flex-none px-2 lg:py-2 w-44 sm:w-5/12 lg:w-4/12 xl:w-1/6 first:pl-0 last:pr-0 h-[300px]">
                <Skeleton className="w-full h-full"></Skeleton>
              </div>
              <div className="flex-none px-2 lg:py-2 w-44 sm:w-5/12 lg:w-4/12 xl:w-1/6 first:pl-0 last:pr-0 h-[300px]">
                <Skeleton className="w-full h-full"></Skeleton>
              </div>
              <div className="flex-none px-2 lg:py-2 w-44 sm:w-5/12 lg:w-4/12 xl:w-1/6 first:pl-0 last:pr-0 h-[300px]">
                <Skeleton className="w-full h-full"></Skeleton>
              </div>
              <div className="flex-none px-2 lg:py-2 w-44 sm:w-5/12 lg:w-4/12 xl:w-1/6 first:pl-0 last:pr-0 h-[300px]">
                <Skeleton className="w-full h-full"></Skeleton>
              </div>
              <div className="flex-none px-2 lg:py-2 w-44 sm:w-5/12 lg:w-4/12 xl:w-1/6 first:pl-0 last:pr-0 h-[300px]">
                <Skeleton className="w-full h-full"></Skeleton>
              </div>
            </>
          ) : (
            data.map((item, index) => <TermItem key={index} data={item} />)
          )}
        </div>
      </div>
    </div>
  );
};

export default ShortTerms;
